@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer utilities {
  @keyframes ping-border {
      75%, 100% {
          transform: scale(1.1);
          opacity: 0;
      }
  }
  .animate-ping-border {
      position: relative;
      --border-color: #3b82f6
  }
  .animate-ping-border::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      border: 2px solid var(--border-color); 
      border-radius: inherit;
      animation: ping-border 6s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .animate-ping-border {
    border-color: var(--border-color);
}
}